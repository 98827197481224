






































































































import {
  defineComponent,
  onMounted,
  provide,
  Ref,
  ref,
  nextTick,
  watch,
} from '@nuxtjs/composition-api';
import {
  SfAccordion,
  SfButton,
  SfFilter,
  SfHeading,
  SfRadio,
  SfSidebar,
} from '@storefront-ui/vue';

import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useUiHelpers } from '~/composables';
import {
  getFilterConfig,
  isFilterEnabled,
} from '~/modules/catalog/category/config/FiltersConfig';
import SelectedFilters from '~/modules/catalog/category/components/filters/FiltersSidebar/SelectedFilters.vue';
import { getProductFilterByCategoryCommand } from '~/modules/catalog/category/components/filters/command/getProductFilterByCategoryCommand';

import type { Aggregation } from '~/modules/GraphQL/types';
import type { SelectedFiltersInterface } from './useFilters';
import { useFilters } from './useFilters';

export interface UseFiltersProviderInterface {
  selectedFilters: Ref<SelectedFiltersInterface>;
  filters: Ref<Aggregation[]>;
}

export default defineComponent({
  name: 'CategoryFilters',
  components: {
    SelectedFilters,
    CheckboxType: () => import(
      '~/modules/catalog/category/components/filters/renderer/CheckboxType.vue'
    ),
    SwatchColorType: () => import(
      '~/modules/catalog/category/components/filters/renderer/SwatchColorType.vue'
    ),
    RadioType: () => import(
      '~/modules/catalog/category/components/filters/renderer/RadioType.vue'
    ),
    YesNoType: () => import(
      '~/modules/catalog/category/components/filters/renderer/YesNoType.vue'
    ),
    SliderType: () => import(
      '~/modules/catalog/category/components/filters/renderer/SliderType.vue'
    ),
    SfSidebar,
    SfHeading,
    SfAccordion,
    SfFilter,
    SfButton,
    SfRadio,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    catUid: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { changeFilters, clearFilters } = useUiHelpers();
    const removableFilters = ref([]);
    const expandedFilters = ref([]);
    const filters = ref<Aggregation[]>([]);
    const isLoading = ref(true);

    const {
      selectedFilters,
      selectFilter,
      selectSliderFilter,
      removeFilter,
      isFilterSelected,
      getRemovableFilters,
    } = useFilters();

    const updateRemovableFilters = () => {
      removableFilters.value = getRemovableFilters(
        filters.value,
        selectedFilters.value,
      );
    };

    const doApplyFilters = () => {
      changeFilters(selectedFilters.value, false);
      updateRemovableFilters();
      if (window?.scroll) {
        window.scroll(0, 0);
      }
      emit('reloadProducts');
      emit('close');
    };

    const doRemoveFilter = ({ id, value }: { id: string; value: string }) => {
      removeFilter(id, value);
      changeFilters(selectedFilters.value, false);
      updateRemovableFilters();
      emit('reloadProducts');
      emit('close');
    };

    const filterIsExpanded = (filter: Aggregation) => expandedFilters.value
      .map(({ attribute_code }) => attribute_code)
      .includes(filter.attribute_code);

    const doExpandFilter = (filter: Aggregation) => {
      if (!filterIsExpanded(filter)) {
        expandedFilters.value.push(filter);
      } else {
        expandedFilters.value = expandedFilters.value.filter(
          ({ attribute_code }) => attribute_code !== filter.attribute_code,
        );
      }
    };

    const doClearFilters = () => {
      clearFilters(false);
      selectedFilters.value = {};
      updateRemovableFilters();
      emit('reloadProducts');
      emit('close');
    };

    watch(
      () => props.isVisible,
      (newValue) => {
        // disable Storefrontt UI's body scroll lock which is launched when :visible prop on SfSidebar changes
        // two next ticks because SfSidebar uses nextTick aswell, and we want to do something after that tick.
        if (newValue) {
          nextTick(() => nextTick(() => clearAllBodyScrollLocks()));
        }
      },
    );

    onMounted(async () => {
      const loadedFilters = await getProductFilterByCategoryCommand.execute({
        eq: props.catUid,
      });
      filters.value = loadedFilters.filter((filter) => isFilterEnabled(filter.attribute_code));
      const sortedFilters = [
        ...filters.value.sort((a, b) => a.position - b.position),
      ];
      // Pushing Price to the last index
      sortedFilters.push(sortedFilters.shift());
      filters.value = sortedFilters;

      updateRemovableFilters();

      removableFilters.value.forEach((filter) => {
        const equivalantFilter = loadedFilters.find(
          (item) => item.attribute_code === filter.id,
        );
        if (equivalantFilter && !filterIsExpanded(equivalantFilter)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          doExpandFilter(equivalantFilter);
        }
      });

      isLoading.value = false;
    });

    provide('UseFiltersProvider', {
      isFilterSelected,
      selectedFilters,
      filters,
    });

    return {
      selectFilter,
      selectSliderFilter,
      doApplyFilters,
      doRemoveFilter,
      doClearFilters,
      doExpandFilter,
      getFilterConfig,
      filterIsExpanded,
      selectedFilters,
      filters,
      isLoading,
      removableFilters,
      expandedFilters,
    };
  },
});
