import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7823def3 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _26e0f5c1 = () => interopDefault(import('../modules/authentication/pages/index.vue' /* webpackChunkName: "" */))
const _0dd93320 = () => interopDefault(import('../modules/authentication/pages/Steps/Login.vue' /* webpackChunkName: "" */))
const _12224728 = () => interopDefault(import('../modules/authentication/pages/Steps/Register.vue' /* webpackChunkName: "" */))
const _93ea46de = () => interopDefault(import('../modules/authentication/pages/Steps/ResetPassword.vue' /* webpackChunkName: "" */))
const _65feae3c = () => interopDefault(import('../modules/authentication/pages/Steps/Platform.vue' /* webpackChunkName: "" */))
const _57a52053 = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _3430ac0e = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _1d4e2a45 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _88050760 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _679d5658 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _9481bf0a = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _4cc5bf2c = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _7412d8e5 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _b9145a70 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _0b732236 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _48a76924 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _3b38e532 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _7c20d494 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _7b3819ba = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _0f362e76 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _6716c782 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _5f12f8f1 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _e8718cd0 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _1538bebd = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _03a1979c = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _6f4e16ba = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _42182390 = () => interopDefault(import('../pages/customer-service/contact-us.vue' /* webpackChunkName: "" */))
const _1c47e90c = () => interopDefault(import('../pages/customer-service/help-center.vue' /* webpackChunkName: "" */))
const _22a2539f = () => interopDefault(import('../pages/customer-service/installation.vue' /* webpackChunkName: "" */))
const _98eb3324 = () => interopDefault(import('../pages/customer-service/returns.vue' /* webpackChunkName: "" */))
const _2d5b07c8 = () => interopDefault(import('../pages/customer-service/tracking.vue' /* webpackChunkName: "" */))
const _829e3520 = () => interopDefault(import('../pages/for-professionals/press-resources.vue' /* webpackChunkName: "" */))
const _4a334488 = () => interopDefault(import('../pages/for-professionals/storefront-as-a-service.vue' /* webpackChunkName: "" */))
const _e1425aa0 = () => interopDefault(import('../pages/for-professionals/trade-program.vue' /* webpackChunkName: "" */))
const _133a61da = () => interopDefault(import('../pages/for-professionals/wholesale.vue' /* webpackChunkName: "" */))
const _a4c913d2 = () => interopDefault(import('../pages/inside-modern-squared/about-us.vue' /* webpackChunkName: "" */))
const _1880259e = () => interopDefault(import('../pages/inside-modern-squared/blog/index.vue' /* webpackChunkName: "" */))
const _2ec4d5a0 = () => interopDefault(import('../pages/inside-modern-squared/sustainability.vue' /* webpackChunkName: "" */))
const _02964bc2 = () => interopDefault(import('../pages/password/CreateNewPassword.vue' /* webpackChunkName: "" */))
const _d1e8b96c = () => interopDefault(import('../pages/support/accessibility.vue' /* webpackChunkName: "" */))
const _3a3a0f6a = () => interopDefault(import('../pages/support/privacy-policy.vue' /* webpackChunkName: "" */))
const _78b069b0 = () => interopDefault(import('../pages/support/terms-and-conditions.vue' /* webpackChunkName: "" */))
const _ab1210f4 = () => interopDefault(import('../pages/inside-modern-squared/blog/_id.vue' /* webpackChunkName: "pages/inside-modern-squared/blog/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/german",
    component: _7823def3,
    name: "home___german"
  }, {
    path: "/us",
    component: _7823def3,
    name: "home___us"
  }, {
    path: "/german/authentication",
    component: _26e0f5c1,
    name: "authentication___german",
    children: [{
      path: "login",
      component: _0dd93320,
      name: "authentication-login___german"
    }, {
      path: "register",
      component: _12224728,
      name: "authentication-register___german"
    }, {
      path: "reset-password",
      component: _93ea46de,
      name: "authentication-reset-password___german"
    }, {
      path: ":platform",
      component: _65feae3c,
      name: "authentication-platform___german"
    }]
  }, {
    path: "/german/cart",
    component: _57a52053,
    name: "cart___german"
  }, {
    path: "/german/checkout",
    component: _3430ac0e,
    name: "checkout___german",
    children: [{
      path: "billing",
      component: _1d4e2a45,
      name: "billing___german"
    }, {
      path: "payment",
      component: _88050760,
      name: "payment___german"
    }, {
      path: "shipping",
      component: _679d5658,
      name: "shipping___german"
    }, {
      path: "thank-you",
      component: _9481bf0a,
      name: "thank-you___german"
    }, {
      path: "user-account",
      component: _4cc5bf2c,
      name: "user-account___german"
    }]
  }, {
    path: "/german/Cms",
    component: _7412d8e5,
    name: "Cms___german"
  }, {
    path: "/german/customer",
    component: _b9145a70,
    meta: {"titleLabel":"My Account"},
    name: "customer___german",
    children: [{
      path: "addresses-details",
      component: _0b732236,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___german"
    }, {
      path: "my-newsletter",
      component: _48a76924,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___german"
    }, {
      path: "my-profile",
      component: _3b38e532,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___german"
    }, {
      path: "my-reviews",
      component: _7c20d494,
      meta: {"titleLabel":"Reviews"},
      name: "customer-my-reviews___german"
    }, {
      path: "my-wishlist",
      component: _7b3819ba,
      meta: {"titleLabel":"Favorites"},
      name: "customer-my-wishlist___german"
    }, {
      path: "order-history",
      component: _0f362e76,
      meta: {"titleLabel":"Orders"},
      name: "customer-order-history___german"
    }, {
      path: "addresses-details/create",
      component: _6716c782,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___german"
    }, {
      path: "/german/reset-password-old",
      component: _5f12f8f1,
      alias: "/customer/account/createPassword",
      name: "reset-password-old___german"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _e8718cd0,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___german"
    }, {
      path: "order-history/:orderId",
      component: _1538bebd,
      meta: {"titleLabel":"Orders"},
      props: true,
      name: "customer-single-order___german"
    }]
  }, {
    path: "/german/Faq",
    component: _03a1979c,
    name: "Faq___german"
  }, {
    path: "/german/Home",
    component: _7823def3,
    name: "Home___german"
  }, {
    path: "/german/Page",
    component: _6f4e16ba,
    name: "Page___german"
  }, {
    path: "/us/authentication",
    component: _26e0f5c1,
    name: "authentication___us",
    children: [{
      path: "login",
      component: _0dd93320,
      name: "authentication-login___us"
    }, {
      path: "register",
      component: _12224728,
      name: "authentication-register___us"
    }, {
      path: "reset-password",
      component: _93ea46de,
      name: "authentication-reset-password___us"
    }, {
      path: ":platform",
      component: _65feae3c,
      name: "authentication-platform___us"
    }]
  }, {
    path: "/us/cart",
    component: _57a52053,
    name: "cart___us"
  }, {
    path: "/us/checkout",
    component: _3430ac0e,
    name: "checkout___us",
    children: [{
      path: "billing",
      component: _1d4e2a45,
      name: "billing___us"
    }, {
      path: "payment",
      component: _88050760,
      name: "payment___us"
    }, {
      path: "shipping",
      component: _679d5658,
      name: "shipping___us"
    }, {
      path: "thank-you",
      component: _9481bf0a,
      name: "thank-you___us"
    }, {
      path: "user-account",
      component: _4cc5bf2c,
      name: "user-account___us"
    }]
  }, {
    path: "/us/Cms",
    component: _7412d8e5,
    name: "Cms___us"
  }, {
    path: "/us/customer",
    component: _b9145a70,
    meta: {"titleLabel":"My Account"},
    name: "customer___us",
    children: [{
      path: "addresses-details",
      component: _0b732236,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___us"
    }, {
      path: "my-newsletter",
      component: _48a76924,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___us"
    }, {
      path: "my-profile",
      component: _3b38e532,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___us"
    }, {
      path: "my-reviews",
      component: _7c20d494,
      meta: {"titleLabel":"Reviews"},
      name: "customer-my-reviews___us"
    }, {
      path: "my-wishlist",
      component: _7b3819ba,
      meta: {"titleLabel":"Favorites"},
      name: "customer-my-wishlist___us"
    }, {
      path: "order-history",
      component: _0f362e76,
      meta: {"titleLabel":"Orders"},
      name: "customer-order-history___us"
    }, {
      path: "addresses-details/create",
      component: _6716c782,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___us"
    }, {
      path: "/us/reset-password-old",
      component: _5f12f8f1,
      alias: "/customer/account/createPassword",
      name: "reset-password-old___us"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _e8718cd0,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___us"
    }, {
      path: "order-history/:orderId",
      component: _1538bebd,
      meta: {"titleLabel":"Orders"},
      props: true,
      name: "customer-single-order___us"
    }]
  }, {
    path: "/us/Faq",
    component: _03a1979c,
    name: "Faq___us"
  }, {
    path: "/us/Home",
    component: _7823def3,
    name: "Home___us"
  }, {
    path: "/us/Page",
    component: _6f4e16ba,
    name: "Page___us"
  }, {
    path: "/german/customer-service/contact-us",
    component: _42182390,
    name: "customer-service/contact-us___german"
  }, {
    path: "/german/customer-service/contact-us",
    component: _42182390,
    name: "customer-service-contact-us___german"
  }, {
    path: "/german/customer-service/help-center",
    component: _1c47e90c,
    name: "customer-service/help-center___german"
  }, {
    path: "/german/customer-service/help-center",
    component: _1c47e90c,
    name: "customer-service-help-center___german"
  }, {
    path: "/german/customer-service/installation",
    component: _22a2539f,
    name: "customer-service/installation___german"
  }, {
    path: "/german/customer-service/installation",
    component: _22a2539f,
    name: "customer-service-installation___german"
  }, {
    path: "/german/customer-service/returns",
    component: _98eb3324,
    name: "customer-service/returns___german"
  }, {
    path: "/german/customer-service/returns",
    component: _98eb3324,
    name: "customer-service-returns___german"
  }, {
    path: "/german/customer-service/tracking",
    component: _2d5b07c8,
    name: "customer-service/tracking___german"
  }, {
    path: "/german/customer-service/tracking",
    component: _2d5b07c8,
    name: "customer-service-tracking___german"
  }, {
    path: "/german/for-professionals/press-resources",
    component: _829e3520,
    name: "for-professionals/press-resources___german"
  }, {
    path: "/german/for-professionals/press-resources",
    component: _829e3520,
    name: "for-professionals-press-resources___german"
  }, {
    path: "/german/for-professionals/storefront-as-a-service",
    component: _4a334488,
    name: "for-professionals/storefront-as-a-service___german"
  }, {
    path: "/german/for-professionals/storefront-as-a-service",
    component: _4a334488,
    name: "for-professionals-storefront-as-a-service___german"
  }, {
    path: "/german/for-professionals/trade-program",
    component: _e1425aa0,
    name: "for-professionals/trade-program___german"
  }, {
    path: "/german/for-professionals/trade-program",
    component: _e1425aa0,
    name: "for-professionals-trade-program___german"
  }, {
    path: "/german/for-professionals/wholesale",
    component: _133a61da,
    name: "for-professionals/wholesale___german"
  }, {
    path: "/german/for-professionals/wholesale",
    component: _133a61da,
    name: "for-professionals-wholesale___german"
  }, {
    path: "/german/inside-modern-squared/about-us",
    component: _a4c913d2,
    name: "inside-modern-squared/about-us___german"
  }, {
    path: "/german/inside-modern-squared/about-us",
    component: _a4c913d2,
    name: "inside-modern-squared-about-us___german"
  }, {
    path: "/german/inside-modern-squared/blog",
    component: _1880259e,
    name: "inside-modern-squared/blog___german"
  }, {
    path: "/german/inside-modern-squared/blog",
    component: _1880259e,
    name: "inside-modern-squared-blog___german"
  }, {
    path: "/german/inside-modern-squared/sustainability",
    component: _2ec4d5a0,
    name: "inside-modern-squared/sustainability___german"
  }, {
    path: "/german/inside-modern-squared/sustainability",
    component: _2ec4d5a0,
    name: "inside-modern-squared-sustainability___german"
  }, {
    path: "/german/password/create-new-password",
    component: _02964bc2,
    name: "password/create-new-password___german"
  }, {
    path: "/german/password/CreateNewPassword",
    component: _02964bc2,
    name: "password-CreateNewPassword___german"
  }, {
    path: "/german/support/accessibility",
    component: _d1e8b96c,
    name: "support/accessibility___german"
  }, {
    path: "/german/support/accessibility",
    component: _d1e8b96c,
    name: "support-accessibility___german"
  }, {
    path: "/german/support/privacy-policy",
    component: _3a3a0f6a,
    name: "support/privacy-policy___german"
  }, {
    path: "/german/support/privacy-policy",
    component: _3a3a0f6a,
    name: "support-privacy-policy___german"
  }, {
    path: "/german/support/terms-and-conditions",
    component: _78b069b0,
    name: "support/terms-and-conditions___german"
  }, {
    path: "/german/support/terms-and-conditions",
    component: _78b069b0,
    name: "support-terms-and-conditions___german"
  }, {
    path: "/us/customer-service/contact-us",
    component: _42182390,
    name: "customer-service/contact-us___us"
  }, {
    path: "/us/customer-service/contact-us",
    component: _42182390,
    name: "customer-service-contact-us___us"
  }, {
    path: "/us/customer-service/help-center",
    component: _1c47e90c,
    name: "customer-service/help-center___us"
  }, {
    path: "/us/customer-service/help-center",
    component: _1c47e90c,
    name: "customer-service-help-center___us"
  }, {
    path: "/us/customer-service/installation",
    component: _22a2539f,
    name: "customer-service/installation___us"
  }, {
    path: "/us/customer-service/installation",
    component: _22a2539f,
    name: "customer-service-installation___us"
  }, {
    path: "/us/customer-service/returns",
    component: _98eb3324,
    name: "customer-service/returns___us"
  }, {
    path: "/us/customer-service/returns",
    component: _98eb3324,
    name: "customer-service-returns___us"
  }, {
    path: "/us/customer-service/tracking",
    component: _2d5b07c8,
    name: "customer-service/tracking___us"
  }, {
    path: "/us/customer-service/tracking",
    component: _2d5b07c8,
    name: "customer-service-tracking___us"
  }, {
    path: "/us/for-professionals/press-resources",
    component: _829e3520,
    name: "for-professionals/press-resources___us"
  }, {
    path: "/us/for-professionals/press-resources",
    component: _829e3520,
    name: "for-professionals-press-resources___us"
  }, {
    path: "/us/for-professionals/storefront-as-a-service",
    component: _4a334488,
    name: "for-professionals/storefront-as-a-service___us"
  }, {
    path: "/us/for-professionals/storefront-as-a-service",
    component: _4a334488,
    name: "for-professionals-storefront-as-a-service___us"
  }, {
    path: "/us/for-professionals/trade-program",
    component: _e1425aa0,
    name: "for-professionals/trade-program___us"
  }, {
    path: "/us/for-professionals/trade-program",
    component: _e1425aa0,
    name: "for-professionals-trade-program___us"
  }, {
    path: "/us/for-professionals/wholesale",
    component: _133a61da,
    name: "for-professionals/wholesale___us"
  }, {
    path: "/us/for-professionals/wholesale",
    component: _133a61da,
    name: "for-professionals-wholesale___us"
  }, {
    path: "/us/inside-modern-squared/about-us",
    component: _a4c913d2,
    name: "inside-modern-squared/about-us___us"
  }, {
    path: "/us/inside-modern-squared/about-us",
    component: _a4c913d2,
    name: "inside-modern-squared-about-us___us"
  }, {
    path: "/us/inside-modern-squared/blog",
    component: _1880259e,
    name: "inside-modern-squared/blog___us"
  }, {
    path: "/us/inside-modern-squared/blog",
    component: _1880259e,
    name: "inside-modern-squared-blog___us"
  }, {
    path: "/us/inside-modern-squared/sustainability",
    component: _2ec4d5a0,
    name: "inside-modern-squared/sustainability___us"
  }, {
    path: "/us/inside-modern-squared/sustainability",
    component: _2ec4d5a0,
    name: "inside-modern-squared-sustainability___us"
  }, {
    path: "/us/password/create-new-password",
    component: _02964bc2,
    name: "password/create-new-password___us"
  }, {
    path: "/us/password/CreateNewPassword",
    component: _02964bc2,
    name: "password-CreateNewPassword___us"
  }, {
    path: "/us/support/accessibility",
    component: _d1e8b96c,
    name: "support/accessibility___us"
  }, {
    path: "/us/support/accessibility",
    component: _d1e8b96c,
    name: "support-accessibility___us"
  }, {
    path: "/us/support/privacy-policy",
    component: _3a3a0f6a,
    name: "support/privacy-policy___us"
  }, {
    path: "/us/support/privacy-policy",
    component: _3a3a0f6a,
    name: "support-privacy-policy___us"
  }, {
    path: "/us/support/terms-and-conditions",
    component: _78b069b0,
    name: "support/terms-and-conditions___us"
  }, {
    path: "/us/support/terms-and-conditions",
    component: _78b069b0,
    name: "support-terms-and-conditions___us"
  }, {
    path: "/german/inside-modern-squared/blog/:id?",
    component: _ab1210f4,
    name: "inside-modern-squared-blog-id___german"
  }, {
    path: "/us/inside-modern-squared/blog/:id?",
    component: _ab1210f4,
    name: "inside-modern-squared-blog-id___us"
  }, {
    path: "/german/:slug+",
    component: _6f4e16ba,
    name: "page___german"
  }, {
    path: "/us/:slug+",
    component: _6f4e16ba,
    name: "page___us"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
